<template>
  <div class="slipA6">
    <div class="customer-part">
      <div class="columns">
        <div class="column is-2">
          <div class="qr-over">
            <vue-qrcode
              :value="'https://kiangkai.com/#/track/' + shipment.code"
              :scale="4"
              :width="qrWidth"
              :margin="2"
            />
          </div>
        </div>
        <div class="column has-text-centered">
          <div class="title is-2">
            <img
              src="../assets/logo_bw.png"
              alt="kiangkai"
              width="100"
              height="100"
              style="vertical-align: middle"
            />
            ກຽງໄກ ຂົນສົ່ງດ່ວນ
          </div>
          <div>ວັນທີຝາກ: {{ shipment.created_date | datetime }}</div>
          <div class="bigger-text"  style="margin-top: -5px ">ເລກບິນ: {{ shipment.code }}</div>
          <div
            style="
              height: 40px;
              overflow: hidden;
              width: 800px;margin-top: -5px
            "
          >
            <vue-barcode
              :value="shipment.code"
              :height="70"
              :width="3"
              :displayValue="false"
              format="CODE128"
            />
          </div>
        </div>
        <div class="column is-2 has-text-centered">
          <div
            style="
              border: 5px solid;
              width: 150px;
              height: 150px;
              font-size: 3em;
              font-weight: 900;
              vertical-align: middle;
              text-align: center;
              margin-top: 30px;
            "
          >
            {{ shipment.sq_number }}
          </div>
        </div>
      </div>


          <div class=" bolder-text " style="font-size: 2em; margin: 4px; border: 2px solid black">

           {{ shipment.to_branch.name }}
          </div>




      <div class="columns">
        <div class="column has-text-left ">
          <div>
            ຜູ້ຝາກ:
          </div>
          <div>
            {{ shipment.from_branch.code }}
            {{ shipment.from_branch.name }} {{ shipment.from_branch.tel }}
          </div>
          <div class="sender-name">{{ shipment.sendername }}</div>
          <div class="sender-name">{{ shipment.sendertel }}</div>
        </div>

        <div class="column has-text-left" >
            <div>
              ຜູ້ຮັບ:
            </div>
          <div>
            {{ shipment.to_branch.code }}
            {{ shipment.to_branch.name }} {{ shipment.to_branch.tel }}
          </div>
            <div class="bigger-text">{{ shipment.receivername }}</div>
            <div class="bigger-text">{{ shipment.receivertel }}</div>

        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-bordered is-narrow">
            <tr>
              <th colspan="7" class="has-text-centered kk-text-small">
                ລາຍການເຄື່ອງຝາກ
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th class="has-text-centered kk-text-small">ປະເພດ</th>
              <th class="has-text-centered kk-text-small">ນໍ້າໜັກ</th>
              <th class="has-text-centered kk-text-small">ຈ/ນ</th>
            </tr>
            <tr v-for="(parcel, index) in shipment.parcel" :key="'x' + index">
              <td>{{ index + 1 }}</td>
              <td class="has-text-centered kk-text-small">
                {{
                  parcel.parceltype.id === 0
                    ? parcel.remark
                    : parcel.parceltype.name
                }}
              </td>
              <td class="has-text-right kk-text-small">
                {{ parcel.weight }} ກິໂລ
              </td>
              <td class="has-text-right kk-text-small">{{ parcel.qty }}</td>
            </tr>
          </table>
        </div>
        <div class="column">
          <table class="table is-fullwidth is-bordered is-narrow">
            <tbody>
              <tr>
                <td>ຄ່າຂົນສົ່ງ</td>
                <td
                  rowspan="2"
                  colspan="2"
                  style="font-size: 1.3em; font-weight: bold"
                >
                  COD
                </td>
                <td rowspan="2">ລວມ</td>
              </tr>
              <tr>
                <td>
                  <p v-if="shipment.is_receiver_pay">ປາຍທາງ</p>
                  <p v-else>{{ paymentMethod }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  {{ shipment.amount | formatnumber }}
                </td>
                <td>ກີບ</td>
                <td>
                  {{ codLak | formatnumber }}
                </td>
                <td>
                  <span style="font-weight: 900; font-size: 1.4em">{{
                    totalLak | formatnumber
                  }}</span>
                </td>
              </tr>
              <tr v-if="shipment.is_cod && codThb > 0">
                <td>{{ shipment.amount_thb | formatnumber }}</td>
                <td>ບາດ</td>
                <td>
                  {{ codThb | formatnumber }}
                </td>
                <td>
                  <span style="font-weight: 900; font-size: 1.4em">{{
                    totalThb | formatnumber
                  }}</span>
                </td>
              </tr>
              <tr v-if="shipment.is_cod && codUsd > 0">
                <td>&nbsp;</td>
                <td>ໂດລາ</td>
                <td>
                  {{ codUsd | formatnumber }}
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <hr style="border-top: 5px dotted; margin-bottom: 40px" />
    <div class="bottom-part">
      <div class="columns">
        <div class="column is-2">
          <div class="qr-over">
            <vue-qrcode
              :value="'https://kiangkai.com/#/track/' + shipment.code"
              :width="qrWidth"
              :margin="2"
            />
          </div>
        </div>
        <div class="column has-text-centered">
          <div class="title is-2">
            <img
              src="../assets/logo_bw.png"
              alt="kiangkai"
              width="100"
              height="100"
              style="vertical-align: middle"
            />
            ກຽງໄກ ຂົນສົ່ງດ່ວນ
          </div>
          <div>ວັນທີຝາກ: {{ shipment.created_date | datetime }}</div>
          <table style="width: 100%;">
            <tr>
            <td class="bigger-text" style="text-align: center;">ເລກບິນ: {{ shipment.code }}</td>
            <td style="text-align: center;"><vue-barcode
              :value="shipment.code"
              :height="30"
              :width="2"
              :displayValue="false"
              format="CODE128"
            /></td>
          </tr>
          </table>
        </div>

        <div class="column is-2 has-text-centered">
          <div
            style="
              border: 5px solid;
              width: 150px;
              height: 150px;
              font-size: 3.3em;
              font-weight: 1000;
              vertical-align: middle;
              text-align: center;
              margin-top: 30px;
            "
          >
            {{ shipment.sq_number }}
          </div>
        </div>
      </div>
      <div class="has-text-centered" style="margin: 10px; padding-bottom: 5px">
        <div
          :class="(shipment.to_branch.name.length>76)? 'branch-name-long':'branch-name' "
        >
          {{ shipment.to_branch.name }}</div
        >
      </div>
      <div class="columns">
        <div class="column has-text-left">
          <div>
            ຜູ້ຝາກ: {{ shipment.from_branch.code }}
            {{ shipment.from_branch.name }} {{ shipment.from_branch.tel }}
          </div>
          <div class="sender-name">{{ shipment.sendername }}</div>
          <div class="sender-name">{{ shipment.sendertel }}</div>
          <div v-if="shipment.is_cod">
            <div v-if="_.find(shipment.cod, { ccy: 'LAK' })">
              ບັນຊີກີບ {{ lakAcc }}
            </div>
            <div v-if="_.find(shipment.cod, { ccy: 'THB' })">
              ບັນຊີບາດ{{ thbAcc }}
            </div>
          </div>
        </div>

        <b-icon icon="arrow-right" size="is-large" />

        <div class="column has-text-left">
          <div>
            ຜູ້ຮັບ: {{ shipment.to_branch.code }} {{ shipment.to_branch.name }}
            {{ shipment.to_branch.tel }}
          </div>
          <div class="bigger-text">{{ shipment.receivername }}</div>
          <div class="bigger-text">{{ shipment.receivertel }}</div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <div style="height: 400px; overflow: hidden">
            <table class="table is-fullwidth is-bordered is-narrow">
              <tr>
                <th colspan="7" class="has-text-centered kk-text-small">
                  ລາຍການເຄື່ອງຝາກ
                </th>
              </tr>
              <tr>
                <th>#</th>
                <th class="has-text-centered kk-text-small">ປະເພດ</th>
                <th class="has-text-centered kk-text-small">ນໍ້າໜັກ</th>
                <th class="has-text-centered kk-text-small">ຈ/ນ</th>
              </tr>

              <tr v-for="(parcel, index) in shipment.parcel" :key="'y' + index">
                <td>{{ index + 1 }}</td>
                <td class="has-text-centered kk-text-small">
                  {{
                    parcel.parceltype.id === 0
                      ? parcel.remark
                      : parcel.parceltype.name
                  }}
                </td>
                <td class="has-text-right kk-text-small">
                  {{ parcel.weight }} ກິໂລ
                </td>
                <td class="has-text-right kk-text-small">{{ parcel.qty }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="column">
          <table class="table is-fullwidth is-bordered is-narrow">
            <tbody>
              <tr>
                <td>ຄ່າຂົນສົ່ງ</td>
                <td
                  rowspan="2"
                  colspan="2"
                  style="font-size: 1.3em; font-weight: bold"
                >
                  COD
                </td>
                <td rowspan="2">ລວມ</td>
              </tr>
              <tr>
                <td>
                  <p v-if="shipment.is_receiver_pay">ປາຍທາງ</p>
                  <p v-else>{{ paymentMethod }}</p>
                </td>
              </tr>
              <tr>
                <td>
                  {{ shipment.amount | formatnumber }}
                </td>
                <td>ກີບ</td>
                <td>
                  {{ codLak | formatnumber }}
                </td>
                <td>
                  <span style="font-weight: 900; font-size: 1.4em">{{
                    totalLak | formatnumber
                  }}</span>
                </td>
              </tr>
              <tr v-if="shipment.is_cod && codThb > 0">
                <td>{{ shipment.amount_thb | formatnumber }}</td>
                <td>ບາດ</td>
                <td>
                  {{ codThb | formatnumber }}
                </td>
                <td>
                  <span style="font-weight: 900; font-size: 1.4em">{{
                    totalThb | formatnumber
                  }}</span>
                </td>
              </tr>
              <tr v-if="shipment.is_cod && codUsd > 0">
                <td>&nbsp;</td>
                <td>ໂດລາ</td>
                <td>
                  {{ codUsd | formatnumber }}
                </td>
                <td>&nbsp;</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="level">
        <div class="level-item has-text-centered">
          <div>
            <p class="title is-6">ລາຍເຊັນ ລູກຄ້າ</p>
          </div>
        </div>
        <div class="level-item has-text-centered">
          <div>
            <p class="title is-6">ລາຍເຊັນ ພະນັກງານ</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "vue-qrcode";
import _ from "lodash";
import VueBarcode from "vue-barcode";
import { get } from "vuex-pathify";

export default {
  name: "ShipmentPrintKkV1",
  components: { VueQrcode, VueBarcode },
  props: {
    shipment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    paymentmethods: get("paymentmethods"),
    paymentMethod() {
      if (this.shipment) {
        let t = this._.find(
          this.paymentmethods,
          (itm) =>
            itm.id.toLowerCase() ===
            this.shipment.fee_payment_mode.toLowerCase()
        );
        if (t !== undefined) {
          return t.name;
        }
      }

      return "ບໍ່ໄດ້ເລືອກ";
    },
    totalLak() {
      return (
        (this.shipment.is_receiver_pay ? this.shipment.amount : 0) + this.codLak
      );
    },
    totalThb() {
      return (
        (this.shipment.is_receiver_pay ? this.shipment.amount_thb : 0) +
        this.codThb
      );
    },
    codLak() {
      if (this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod, (itm) =>
          itm.ccy === "LAK" ? itm.amount : 0
        );
      }
      return 0;
    },
    codThb() {
      if (this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod, (itm) =>
          itm.ccy === "THB" ? itm.amount : 0
        );
      }
      return 0;
    },
    codUsd() {
      if (this.shipment.is_cod) {
        return _.sumBy(this.shipment.cod, (itm) =>
          itm.ccy === "USD" ? itm.amount : 0
        );
      }
      return 0;
    },
    lakAcc() {
      if (!this.shipment.customer) return "-";
      const acc = _.find(this.shipment.customer.customer_account, {
        ccy: "LAK",
      });
      if (acc === undefined) return "-";
      return acc.account + "-" + acc.bankid;
    },
    thbAcc() {
      if (!this.shipment.customer) return "-";
      const acc = _.find(this.shipment.customer.customer_account, {
        ccy: "THB",
      });
      if (acc === undefined) return "-";
      return acc.account + "-" + acc.bankid;
    },
  },
  data() {
    return {
      qrWidth: 180,
    };
  },
};
</script>

<style scoped>
* {
  color: #000000 !important;
  font-family: "NotosansLao" !important;
  font-weight: bolder !important;
}

.slipA6 {
  text-align: center;
  font-weight: 900 !important;
  font-size: 1.5em !important;
  padding: 8px;
  width: 1240px;
  height: 1748px !important;
  color: #000000 !important;
  /*background-color: #FAFAFA;*/
  overflow: hidden;
}

.customer-part {
  height: 810px;
  /*  background-color: #F2F2F2;*/
  overflow: hidden;
}

.bolder-text {
  font-weight: 900 !important;
}
.sender-name {
  font-size: 1.3em;
}
.bigger-text {
  font-size: 1.4em;
}

.summary-table td,
.summary-table th {
  font-weight: 900 !important;
  font-size: 2em !important;
}

.table.is-bordered td,
.table.is-bordered th {
  border-width: 3px;
  border-color: #000000;
}

.table.is-bordered tr {
  border-width: 3px;
  border-color: #000000;
}

.qr-over {
  height: 150px;
  overflow: visible;
}

.branch-name {
  font-size: 4em; 
  color: black !important; 
  border: 5px solid black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px;
}
.branch-name-long{
  font-size: 4em; 
  color: black !important; 
  border: 5px solid black;
  line-height: 1.2;
  padding: 7px;
}
</style>